<template>
  <ContentCard :card-height="24">
    <template v-slot:header>
      <h3 class="primary-text" style="margin-top: 0.5rem">My Languages</h3>
      <AddAttributeButton
        v-if="!isPublic"
        button-title="Add One"
        :open-modal="false"
      >
        <EditProfileDropdown
          :dropdown-menu-items="languages"
          :selected-items="selectedLanguages"
          @item-clicked="onSelectLanguage"
        />
      </AddAttributeButton>
    </template>
    <template v-slot:body>
      <div
        v-loading="loading"
        v-if="!expertLanguages.length"
        class="d-flex flex-column h-100 align-items-center justify-content-center"
      >
        <el-avatar :size="100" :src="'/media/emptyCard/empty_1.png'">
          <img :src="'/media/emptyCard/empty_1.png'" :alt="'empty_card'"/>
        </el-avatar>
        <h4 class="primary-text text-center mt-6">
          Please add some info
        </h4>
      </div>
      <div v-else class="d-flex flex-column h-100 pt-10" v-loading="loading">
        <div class="flex-grow-1">
          <div
            class="d-flex justify-content-between align-items-center mb-5"
            v-for="expertLanguage in expertLanguages"
            :key="expertLanguage.id"
          >
            <h6 class="primary-text">
              <el-icon
                @click="onDeleteLanguage(expertLanguage.language)"
                v-if="!isPublic"
                class="el-icon--left cursor-pointer"
                ><CircleCloseFilled /></el-icon
              >{{ expertLanguage.language.name }}
            </h6>
            <el-button
              class="btn-success"
              style="color: white"
              size="mini"
              round
              data-kt-menu-trigger="click"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="top"
              data-kt-menu-flip="top"
              >{{ expertLanguage.languageLevel.name }}</el-button
            >
            <EditProfileDropdown
              v-if="!isPublic"
              :dropdown-menu-items="languageLevels"
              @item-clicked="
                onSelectLanguageLevel($event, expertLanguage.language.id)
              "
            />
          </div>
        </div>
      </div>
    </template>
  </ContentCard>
</template>

<script>
import ContentCard from "../../common/components/ContentCard";
import { computed, onMounted, ref } from "vue";
import AddAttributeButton from "../../common/components/AddAttributeButton";
import {
  addExpertLanguage,
  deleteExpertLanguage,
  getExpertLanguages,
  getLanguageLevels,
  getLanguages,
  updateExpertLanguage,
} from "../../api/expert.api";
import EditProfileDropdown from "./EditProfileDropdown";
import { ElNotification } from "element-plus";
import { useRoute } from "vue-router";
import { MenuComponent } from "../../assets/ts/components";
import { CircleCloseFilled } from "@element-plus/icons-vue";
export default {
  name: "ExpertLanguages",
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditProfileDropdown,
    AddAttributeButton,
    ContentCard,
    CircleCloseFilled,
  },
  setup() {
    const route = useRoute();
    const expertId = computed(() => route.params.expertId);
    const expertLanguages = ref([]);
    const languages = ref([]);
    const selectedLanguages = ref([]);
    const languageLevels = ref([]);
    const loading = ref(false);

    onMounted(async () => {
      try {
        loading.value = true;
        await fetchExpertLanguages();
        await fetchLanguages();
        await fetchLanguageLevels();
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    });

    const fetchLanguageLevels = async () => {
      const { data } = await getLanguageLevels();
      languageLevels.value = data.data;
    };

    const fetchExpertLanguages = async () => {
      const { data } = await getExpertLanguages(expertId.value);
      for (const expertLanguage of data.data) {
        selectedLanguages.value.push(expertLanguage.language);
      }
      expertLanguages.value = data.data;
    };

    const fetchLanguages = async () => {
      const { data } = await getLanguages();
      languages.value = data.data;
    };

    const onSelectLanguage = async (language) => {
      try {
        loading.value = true;
        selectedLanguages.value.push(language);
        await addExpertLanguage({
          languageId: language.id,
          languageLevelId: 1,
        });
        await fetchExpertLanguages();
        MenuComponent.reinitialization();
        ElNotification({
          title: "Success",
          message: "Language Added.",
          type: "success",
        });
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Language Not Added",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };

    const onSelectLanguageLevel = async (languageLevel, languageId) => {
      try {
        loading.value = true;
        await updateExpertLanguage({
          languageId: languageId,
          languageLevelId: languageLevel.id,
        });
        await fetchExpertLanguages();
        ElNotification({
          title: "Success",
          message: "Language Level Updated.",
          type: "success",
        });
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Language Level Not Updated",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };

    const onDeleteLanguage = async (language) => {
      try {
        loading.value = true;
        selectedLanguages.value = selectedLanguages.value.filter(
          (selectedLanguage) => selectedLanguage.id !== language.id
        );
        await deleteExpertLanguage(language.id);
        await fetchExpertLanguages();
        ElNotification({
          title: "Success",
          message: "Language Deleted.",
          type: "success",
        });
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Language Not Deleted",
          type: "error",
        });
        selectedLanguages.value.push(language);
      } finally {
        loading.value = false;
      }
    };

    return {
      expertLanguages,
      languages,
      selectedLanguages,
      languageLevels,
      expertId,
      loading,
      onSelectLanguage,
      onSelectLanguageLevel,
      onDeleteLanguage,
    };
  },
};
</script>

<style scoped></style>
