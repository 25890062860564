<template>
  <ContentCard :card-height="24" v-loading="loading">
    <template v-slot:header>
      <h3 class="primary-text" style="margin-top: 0.5rem">
        Preferred Startup Status
      </h3>
    </template>
    <template v-slot:body>
      <el-row class="pt-10">
        <el-col
          :span="8"
          v-for="(investmentRange, index) in projectStatuses"
          :key="index"
        >
          <InvestmentRangeType
            :icon="icons[index]"
            :is-public="isPublic"
            :range-type="investmentRange"
            @item-clicked="onSelectStatus"
          />
        </el-col>
      </el-row>
    </template>
  </ContentCard>
</template>

<script>
import ContentCard from "../../common/components/ContentCard";
import { computed, onMounted, ref, watch } from "vue";
import InvestmentRangeType from "./InvestmentRangeType";
import {
  addExpertProjectStatus,
  deleteExpertProjectStatus,
  getExpertProjectStatuses,
} from "@/api/expert.api";
import { ElNotification } from "element-plus";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
export default {
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  name: "ExpertInvestmentRange",
  components: { InvestmentRangeType, ContentCard },
  setup(props) {
    const route = useRoute();
    const store = useStore();
    const loading = ref(false);
    const expertId = computed(() => route.params.expertId);
    const projectStatuses = ref([]);
    const expertProjectStatues = ref([]);
    const icons = ref([
      "/media/svg/investment-ranges/idea-stage.svg",
      "/media/svg/investment-ranges/mvp-stage.svg",
      "/media/svg/investment-ranges/live-without-revenue.svg",
      "/media/svg/investment-ranges/live-with-revenue.svg",
      "/media/svg/investment-ranges/investors-board.svg",
      "/media/svg/investment-ranges/profitable.svg",
    ]);

    const expertProjectStatusesComputed = computed(() => {
      return store.getters["ExpertModule/getExpertProjectStatuses"];
    });

    const allProjectStatusesComputed = computed(() => {
      return store.getters["UserModule/getAllProjectStatuses"];
    });

    onMounted(async () => {
      loading.value = true;
      if (props.isPublic) {
        const { data } = await getExpertProjectStatuses(expertId.value);
        for (const investmentRange of data.data) {
          expertProjectStatues.value.push(investmentRange.projectStatus.id);
        }
        projectStatuses.value = projectStatuses.value.map((projectStatus) => {
          if (expertProjectStatues.value.includes(projectStatus.id)) {
            return {
              ...projectStatus,
              selected: true,
            };
          } else {
            return {
              ...projectStatus,
              selected: false,
            };
          }
        });
      }
      loading.value = false;
    });

    const onSelectStatus = async (status) => {
      const index = projectStatuses.value.findIndex(
        (item) => item.id === status.id
      );
      const isSelected = projectStatuses.value[index].selected;
      projectStatuses.value[index].selected = !isSelected;
      loading.value = true;
      try {
        if (isSelected) {
          await deleteExpertProjectStatus(status.id);
          ElNotification({
            title: "Success",
            message: "Project Status Deleted",
            type: "success",
          });
        } else {
          await addExpertProjectStatus(status.id);
          ElNotification({
            title: "Success",
            message: "Project Status Added",
            type: "success",
          });
        }
        await store.dispatch("ExpertModule/fetchExpertProjectStatuses", true);
      } catch (e) {
        projectStatuses.value[index].selected =
          !projectStatuses.value[index].selected;
        ElNotification({
          title: "Error",
          message: "An error has occurred",
          type: "error",
        });
      }
      loading.value = false;
    };

    watch(
      () => expertProjectStatusesComputed.value,
      (value) => {
        if (!props.isPublic) {
          expertProjectStatues.value = [];
          for (const investmentRange of value) {
            expertProjectStatues.value.push(investmentRange.projectStatus.id);
          }
          projectStatuses.value = projectStatuses.value.map((projectStatus) => {
            if (expertProjectStatues.value.includes(projectStatus.id)) {
              return {
                ...projectStatus,
                selected: true,
              };
            } else {
              return {
                ...projectStatus,
                selected: false,
              };
            }
          });
        }
      },
      { deep: true, immediate: true }
    );

    watch(
      () => allProjectStatusesComputed.value,
      (value) => {
        projectStatuses.value = value.map((projectStatus) => {
          if (expertProjectStatues.value.includes(projectStatus.id)) {
            return {
              ...projectStatus,
              selected: true,
            };
          } else {
            return {
              ...projectStatus,
              selected: false,
            };
          }
        });
      },
      { deep: true, immediate: true }
    );

    return { projectStatuses, expertId, loading, onSelectStatus, icons };
  },
};
</script>

<style scoped></style>
