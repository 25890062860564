<template>
  <div v-loading="loading">
    <ExpertSelectionsCard
      :is-public="isPublic"
      @item-to-add="addBusinessModel"
      @item-to-delete="deleteBusinessModel"
      :dropdown-menu-items="businessModels"
      title="My preferred Business Models"
      :items="expertBusinessModels"
    />
  </div>
</template>

<script>
import ExpertSelectionsCard from "./ExpertSelectionsCard";
import { computed, onMounted, ref } from "vue";
import {
  addExpertBusinessModel,
  deleteExpertBusinessModel,
  getBusinessModelsOfExpert,
} from "../../api/expert.api";
import { getAllBusinessModels } from "../../api/user.api";
import { ElNotification } from "element-plus";
import { useRoute } from "vue-router";
export default {
  name: "ExpertBusinessModels",
  components: { ExpertSelectionsCard },
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const route = useRoute();
    const expertId = computed(() => route.params.expertId);
    const expertBusinessModels = ref([]);
    const businessModels = ref([]);
    const loading = ref(true);

    onMounted(async () => {
      try {
        businessModels.value = await getBusinessModels();
        const businessModelsOfExperts = await getExpertBusinessModels();
        for (const expertBusinessModel of businessModelsOfExperts) {
          expertBusinessModels.value.push(expertBusinessModel.businessModel);
        }
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    });

    const getBusinessModels = async () => {
      const { data } = await getAllBusinessModels();
      return data.data;
    };

    const getExpertBusinessModels = async () => {
      const { data } = await getBusinessModelsOfExpert(expertId.value);
      return data.data;
    };

    const addBusinessModel = async (businessModel) => {
      const preservedValue = [...expertBusinessModels.value];
      expertBusinessModels.value.push(businessModel);
      try {
        await addExpertBusinessModel(businessModel.id);
        ElNotification({
          title: "Success",
          message: "Business Model Added",
          type: "success",
        });
      } catch (e) {
        expertBusinessModels.value = [...preservedValue];
        ElNotification({
          title: "Error",
          message: "Business Model Not Added",
          type: "error",
        });
      }
    };

    const deleteBusinessModel = async (businessModelId) => {
      const preservedValue = [...businessModels.value];
      expertBusinessModels.value = expertBusinessModels.value.filter(
        (businessModel) => businessModel.id !== businessModelId
      );
      try {
        await deleteExpertBusinessModel(businessModelId);
        ElNotification({
          title: "Success",
          message: "Business Model Deleted",
          type: "success",
        });
      } catch (e) {
        businessModels.value = [...preservedValue];
        ElNotification({
          title: "Error",
          message: "Business Model Not Deleted",
          type: "error",
        });
      }
    };

    return {
      expertBusinessModels,
      businessModels,
      loading,
      deleteBusinessModel,
      addBusinessModel,
    };
  },
};
</script>

<style scoped></style>
