<template>
  <div
    class="modal fade"
    id="discount_edit_modal"
    tabindex="-1"
    aria-hidden="true"
    v-loading="loading"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Discount Rate</h2>
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>
        <div class="modal-body py-10 px-lg-17">
          <h6 class="primary-text mb-5">
            Select {{ rate.expertRates ? rate.expertRates.type : "" }} rate
          </h6>
          <div class="w-100 text-center">
            <el-radio v-model="radioValue" :label="25" size="large" border
              >25%</el-radio
            >
            <el-radio v-model="radioValue" :label="50" size="large" border
              >50%</el-radio
            >
            <el-radio v-model="radioValue" :label="75" size="large" border
              >75%</el-radio
            >
            <el-radio v-model="radioValue" :label="100" size="large" border
              >100%</el-radio
            >
          </div>
        </div>
        <div class="modal-footer flex-center">
          <button
            type="reset"
            data-bs-dismiss="modal"
            class="btn btn-white me-3"
          >
            Discard
          </button>
          <button
            @click="rateChanged"
            ref="submitButtonRef"
            type="button"
            :disabled="!radioValue"
            class="btn btn-primary"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
  <button
    hidden
    ref="modalRefButton"
    data-bs-toggle="modal"
    data-bs-target="#discount_edit_modal"
  />
</template>

<script>
import { ref, watch } from "vue";
import { saveExpertRates } from "@/api/expert.api";
import { useStore } from "vuex";

export default {
  name: "ExpertDiscountEditModal",
  props: {
    rate: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const modalRefButton = ref(null);
    const radioValue = ref(null);
    const loading = ref(false);
    const store = useStore();
    const showModal = () => {
      modalRefButton.value.click();
      radioValue.value = props.rate.rate;
    };

    const hideModal = () => {
      modalRefButton.value.click();
      radioValue.value = null;
    };

    const rateChanged = async () => {
      loading.value = true;
      try {
        await saveExpertRates([
          {
            rate: props.rate.rate,
            discountPercentage: radioValue.value,
            expertRateId: props.rate.expertRates.id,
          },
        ]);
        await store.dispatch("ExpertModule/fetchExpertRates", true);
        hideModal();
      } catch {
        //
      } finally {
        loading.value = false;
      }
    };

    watch(
      () => props.rate,
      (value) => {
        radioValue.value = value.discountPercentage
          ? value.discountPercentage
          : null;
      },
      { immediate: true, deep: true }
    );

    return {
      modalRefButton,
      radioValue,
      loading,
      showModal,
      rateChanged,
    };
  },
};
</script>

<style scoped></style>
