<template>
  <ContentCard :card-height="43">
    <template v-slot:header>
      <h3 class="primary-text align-self-center">
        {{ isPublic ? "Skills" : "My Skills" }}
      </h3>
      <AddAttributeButton
        v-if="!isPublic && expertSkills.length < 5"
        button-title="Add One"
        :open-modal="false"
      >
        <EditProfileModal
          :dropdown-menu-items="skillsWithGroups"
          :has-submenu="true"
          @item-clicked="addToSkillsArray"
        />
      </AddAttributeButton>
    </template>
    <template v-slot:body>
      <div
        v-loading="loading"
        v-if="!expertSkills.length"
        class="d-flex flex-column h-100 align-items-center justify-content-center"
      >
        <el-avatar :size="250" :src="'/media/emptyCard/empty_1.png'">
          <img :src="'/media/emptyCard/empty_1.png'" :alt="'empty_card'"/>
        </el-avatar>
        <h4 class="primary-text text-center mt-10">
            Please add some info
        </h4>
      </div>
      <div v-else class="d-flex flex-column h-100 pt-10" v-loading="loading">
        <div class="flex-grow-1">
          <div
            v-for="(expertSkill, index) in expertSkills"
            :key="index"
            class="d-flex justify-content-between align-items-center skill-container mb-5"
          >
            <div v-if="!isPublic" class="skill-name d-flex primary-text">
              <el-icon
                @click="onDeleteExpertSkill(expertSkill.skills.id)"
                class="el-icon--left cursor-pointer"
                ><CircleCloseFilled
              /></el-icon>
              <h6 class="primary-text">{{ expertSkill.skills.name }}</h6>
            </div>
            <div class="slider flex-grow-1 d-flex align-items-center">
              <el-slider
                :max="20"
                :disabled="expertId || isPublic"
                class="flex-grow-1"
                v-model="expertSkill.noOfYears"
                @change="
                  (newVal) =>
                    onChangeExpertSkill(newVal, expertSkill.skills.id, index)
                "
              ></el-slider>
              <span class="years primary-color fw-bolder">{{
                `${expertSkill.noOfYears} yrs`
              }}</span>
            </div>
            <div class="skill-level text-center">
              <el-button
                v-if="expertSkill.skillLevel || !isPublic"
                :data-kt-menu-trigger="isPublic ? '' : 'click'"
                :data-kt-menu-attach="isPublic ? '' : 'parent'"
                :data-kt-menu-placement="isPublic ? '' : 'bottom-end'"
                :data-kt-menu-flip="isPublic ? '' : 'bottom'"
                class="btn btn-sm"
                :class="{
                  'btn-success': expertSkill.skillLevel,
                  'btn-secondary': !expertSkill.skillLevel,
                }"
                size="mini"
                round
                >{{
                  expertSkill.skillLevel
                    ? expertSkill.skillLevel.name
                    : "Select skill level"
                }}</el-button
              >
              <EditProfileDropdown
                @item-clicked="
                  onSelectSkillLevel($event, expertSkill.skills.id, index)
                "
                ref="editProfileDropDown"
                :dropdown-menu-items="skillLevels"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </ContentCard>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import AddAttributeButton from "../../common/components/AddAttributeButton";
import ContentCard from "../../common/components/ContentCard";
import { useStore } from "vuex";
import { addExpertSkills, deleteExpertSkill } from "@/api/expert.api";
import EditProfileDropdown from "./EditProfileDropdown";
import EditProfileModal from "./EditProfileModal";
import { MenuComponent } from "@/assets/ts/components";
import { ElNotification } from "element-plus";
import { useRoute } from "vue-router";
import { CircleCloseFilled } from "@element-plus/icons-vue";

export default {
  name: "ExpertSkills",
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AddAttributeButton,
    ContentCard,
    EditProfileDropdown,
    EditProfileModal,
    CircleCloseFilled,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const expertSkills = computed(
      () => store.getters["ExpertModule/getExpertSkills"]
    );
    const skillsWithGroups = computed(
      () => store.getters["ExpertModule/getSkillsWithGroups"]
    );
    const skillLevels = computed(
      () => store.getters["ExpertModule/getSkillLevel"]
    );
    const expertId = computed(() => route.params.expertId);
    const payload = ref([]);
    const loading = ref(false);

    onMounted(async () => {
      await store.dispatch("ExpertModule/fetchExpertSkills", expertId.value);
      await store.dispatch("ExpertModule/fetchSkillLevels");
      for (const skill of expertSkills.value) {
        payload.value.push({
          skillId: skill.skills.id,
          noOfYears: skill.noOfYears,
          skillLevelId: skill.skillLevel ? skill.skillLevel.id : null,
        });
      }
    });

    const addToSkillsArray = async (skill) => {
      payload.value.push({
        skillId: skill.id,
        noOfYears: 1,
        skillLevelId: null,
      });
      await saveChanges();
    };

    const onChangeExpertSkill = async (noOfYears, skillId) => {
      const index = payload.value.findIndex((item) => item.skillId === skillId);
      if (index !== -1) {
        payload.value[index].noOfYears = noOfYears;
        await saveChanges();
      }
    };

    const onSelectSkillLevel = async (skillLevel, skillId, indexValue) => {
      const index = payload.value.findIndex((item) => item.skillId === skillId);
      payload.value[index].skillLevelId = skillLevel.id;
      await saveChanges();
    };

    const onDeleteExpertSkill = async (skillId) => {
      try {
        loading.value = true;
        await deleteExpertSkill(skillId);
        await store.dispatch("ExpertModule/fetchExpertSkills", expertId.value);
        payload.value = payload.value.filter((val) => val.skillId !== skillId);
        ElNotification({
          title: "Success",
          message: "Skill deleted.",
          type: "success",
        });
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Skill not deleted.",
          type: "error",
        });
      } finally {
        loading.value = false;
        MenuComponent.reinitialization();
      }
    };

    const saveChanges = async () => {
      try {
        loading.value = true;
        await addExpertSkills({
          expertId: store.getters["UserModule/getExpertId"],
          skillDTO: payload.value,
        });
        await store.dispatch("ExpertModule/fetchExpertSkills", expertId.value);

        ElNotification({
          title: "Success",
          message: "Changes saved.",
          type: "success",
        });
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
        setTimeout(() => {
          MenuComponent.reinitialization();
        }, 0);
      }
    };

    return {
      expertSkills,
      skillsWithGroups,
      skillLevels,
      loading,
      expertId,
      addToSkillsArray,
      saveChanges,
      onChangeExpertSkill,
      onSelectSkillLevel,
      onDeleteExpertSkill,
    };
  },
  watch: {
    expertSkills: {
      handler() {
        setTimeout(() => {
          MenuComponent.reinitialization();
        }, 0);
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.button-container {
  border-style: solid;
  padding: 1rem 0.15rem;
  .button-title {
    padding-left: 0.5rem;
  }
}
.skill-container {
  .skill-name {
    width: 29%;
  }
  .slider {
    padding-left: 2rem;
    padding-right: 2rem;
    .years {
      padding-left: 1rem;
    }
  }
  .skill-level {
    width: 20%;
  }
}
</style>
