const RatesMenuConfig = {
  perHour: {
    buttonTitle: "Min rate per hour",
    dropdownMenuItems: [
      25, 50, 75, 100, 125, 150, 175, 200, 250, 300, 350, 400, 450, 500, 550,
      600, 650, 700, 750, 800, 850, 900, 950, 1000,
    ],
  },
  perDay: {
    buttonTitle: "Min rate per day",
    dropdownMenuItems: [
      200, 300, 400, 500, 600, 800, 1000, 1200, 1400, 1600, 1800, 2000, 2200,
      2400, 2600, 2800, 3000, 3200, 3400, 3600, 3800, 4000, 4200, 4400, 4600,
      4800, 5000,
    ],
  },
  perIntro: {
    buttonTitle: "Min rate per intro",
    dropdownMenuItems: [
      500, 1000, 1500, 2000, 2500, 5000, 7500, 10000, 12500, 15000, 17500,
      20000, 22500, 25000, 30000, 35000, 40000, 45000, 50000, 60000, 70000,
      80000, 90000, 100000,
    ],
  },
};

export default RatesMenuConfig;
