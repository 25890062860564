<template>
  <div v-loading="loading">
    <ExpertSelectionsCard
      :is-public="isPublic"
      @item-to-add="addCustomerGroup"
      @item-to-delete="deleteCustomerGroup"
      :dropdown-menu-items="customerGroups"
      title="My preferred Customer Groups"
      :items="expertCustomerGroups"
    />
  </div>
</template>

<script>
import ExpertSelectionsCard from "./ExpertSelectionsCard";
import { computed, onMounted, ref } from "vue";
import {
  addExpertCustomerGroup,
  deleteExpertCustomerGroup,
  getCustomerGroupsOfExpert,
} from "../../api/expert.api";
import { getAllCustomerGroups } from "../../api/user.api";
import { ElNotification } from "element-plus";
import { useRoute } from "vue-router";
export default {
  name: "ExpertCustomerGroups",
  components: { ExpertSelectionsCard },
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const route = useRoute();
    const expertId = computed(() => route.params.expertId);
    const expertCustomerGroups = ref([]);
    const customerGroups = ref([]);
    const loading = ref(true);

    onMounted(async () => {
      try {
        customerGroups.value = await getCustomerGroups();
        const customerGroupsOfExperts = await getExpertCustomerGroups();
        for (const expertCustomerGroup of customerGroupsOfExperts) {
          expertCustomerGroups.value.push(expertCustomerGroup.customerGroup);
        }
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    });

    const getCustomerGroups = async () => {
      const { data } = await getAllCustomerGroups();
      return data.data;
    };

    const getExpertCustomerGroups = async () => {
      const { data } = await getCustomerGroupsOfExpert(expertId.value);
      return data.data;
    };

    const addCustomerGroup = async (customerGroup) => {
      const preservedValue = [...expertCustomerGroups.value];
      expertCustomerGroups.value.push(customerGroup);
      try {
        await addExpertCustomerGroup(customerGroup.id);
        ElNotification({
          title: "Success",
          message: "Customer Group Added",
          type: "success",
        });
      } catch (e) {
        expertCustomerGroups.value = [...preservedValue];
        ElNotification({
          title: "Error",
          message: "Customer Group Not Added",
          type: "error",
        });
      }
    };

    const deleteCustomerGroup = async (customerGroupId) => {
      const preservedValue = [...customerGroups.value];
      expertCustomerGroups.value = expertCustomerGroups.value.filter(
        (customerGroup) => customerGroup.id !== customerGroupId
      );
      try {
        await deleteExpertCustomerGroup(customerGroupId);
        ElNotification({
          title: "Success",
          message: "Customer Group Deleted",
          type: "success",
        });
      } catch (e) {
        customerGroups.value = [...preservedValue];
        ElNotification({
          title: "Error",
          message: "Customer Group Not Deleted",
          type: "error",
        });
      }
    };

    return {
      expertCustomerGroups,
      customerGroups,
      loading,
      deleteCustomerGroup,
      addCustomerGroup,
    };
  },
};
</script>

<style scoped></style>
