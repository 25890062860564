<template>
  <ContentCard :card-height="24">
    <template v-slot:header>
      <h3 class="primary-text" style="margin-top: 0.5rem">
        My Work Experience
      </h3>
      <div v-if="!isPublic">
        <AddAttributeButton
          @buttonClicked="clearFormData"
          button-title="Add One"
          ref="addCompanyButtonRef"
          modal-id="workExperienceModal"
          :open-modal="true"
        >
          <AddCompanyModal
            ref="modalRef"
            modal-id="workExperienceModal"
            title="Add Company"
          >
            <Form
              id="kt_modal_create_api_key_form"
              class="form"
              ref="addWorkExperienceForm"
              @submit="submit"
              :validation-schema="validationSchema"
            >
              <div class="modal-body py-10 px-lg-17">
                <div
                  class="scroll-y me-n7 pe-7"
                  id="kt_modal_create_api_key_scroll"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-max-height="auto"
                  data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                  data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                  data-kt-scroll-offset="300px"
                >
                  <div class="mb-5 fv-row w-100 text-center">
                    <el-avatar
                      class="cursor-pointer"
                      @click="handleClick"
                      :size="100"
                      :src="displayImage"
                    ></el-avatar>
                    <input
                      name="uploadAvatar"
                      @input="handleFileSelected"
                      type="file"
                      style="display: none"
                      ref="hiddenInputRef"
                    /><br />
                    <label class="fs-5 fw-bold mb-2">Logo</label>
                  </div>
                  <div class="mb-5 fv-row">
                    <label class="required fs-5 fw-bold mb-2"
                      >Company Name</label
                    >
                    <Field
                      type="text"
                      class="form-control form-control-solid"
                      placeholder="Company Name"
                      name="companyName"
                      v-model="formData.companyName"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="companyName" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer flex-center">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  class="btn btn-white me-3"
                >
                  Discard
                </button>
                <button
                  ref="submitButtonRef"
                  type="submit"
                  id="kt_modal_create_api_key_submit"
                  class="btn btn-primary"
                >
                  <span class="indicator-label"> Submit </span>
                  <span class="indicator-progress">
                    Please wait...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
              </div>
            </Form>
          </AddCompanyModal>
        </AddAttributeButton>
      </div>
    </template>
    <template v-slot:body>
      <div
        v-loading="loading"
        v-if="!expertWorkedCompanies.length"
        class="d-flex flex-column h-100 align-items-center justify-content-center"
      >
        <el-avatar :size="100" :src="'/media/emptyCard/empty_1.png'">
          <img :src="'/media/emptyCard/empty_1.png'" :alt="'empty_card'"/>
        </el-avatar>
        <h4 class="primary-text text-center mt-6">
          Please add some info
        </h4>
      </div>
      <div v-else v-loading="loading" class="d-flex flex-column h-100 pt-10">
        <div class="flex-grow-1">
          <div
            v-for="(company, index) in expertWorkedCompanies"
            :key="index"
            class="d-flex justify-content-between align-items-center mb-5"
          >
            <div
              class="d-flex align-items-center justify-content-between w-100"
            >
              <div class="d-flex align-items-center">
                <div class="symbol symbol-50px w-50px bg-light">
                  <img
                    :src="
                      company.logoURL
                        ? company.logoURL
                        : '/media/svg/iconmonstr-building-20.svg'
                    "
                    style="object-fit: cover"
                    alt="image"
                    class="p-3 img-fluid"
                  />
                </div>
                <h6
                  class="company-name primary-text mt-2"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="(company.companyName && company.companyName.length > 14) ? company.companyName : ''">
                  {{ company.companyName }}
                </h6>
              </div>
              <div>
                <el-icon
                  v-if="!isPublic"
                  @click="editWorkExperienceCompany(company)"
                  class="el-icon--left cursor-pointer primary-text"
                  ><Edit
                /></el-icon>
                <el-icon
                  v-if="!isPublic"
                  @click="deleteCompany(company.id)"
                  class="el-icon--left cursor-pointer primary-text"
                  ><CircleCloseFilled
                /></el-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ContentCard>
</template>

<script>
import ContentCard from "../../common/components/ContentCard";
import { computed, onMounted, ref, watch } from "vue";
import AddAttributeButton from "../../common/components/AddAttributeButton";
import {
  deleteWorkExperienceOfExpert,
  addWorkExperienceOfExpert,
  updateWorkExperienceOfExpert,
} from "@/api/expert.api";
import AddCompanyModal from "./AddCompanyModal";
import { ErrorMessage, Field, Form } from "vee-validate";
import { ElNotification } from "element-plus";
import * as Yup from "yup";
import { useRoute } from "vue-router";
import { CircleCloseFilled } from "@element-plus/icons-vue";
import { useStore } from "vuex";
import { Edit } from "@element-plus/icons";

export default {
  name: "ExpertWorkedCompanies",
  components: {
    Edit,
    AddCompanyModal,
    AddAttributeButton,
    ContentCard,
    ErrorMessage,
    Field,
    Form,
    CircleCloseFilled,
  },
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const expertId = computed(() => route.params.expertId);
    const expertWorkedCompanies = ref([]);
    const submitButtonRef = ref(null);
    const addCompanyButtonRef = ref(null);
    const addWorkExperienceForm = ref(null);
    const companyToBeEdited = ref({});
    const hiddenInputRef = ref(null);
    const modalRef = ref(null);
    const loading = ref(true);
    const displayImage = ref("");
    const formData = ref({
      companyName: "",
    });
    const validationSchema = Yup.object().shape({
      companyName: Yup.string().required().label("Company name"),
    });

    const expertWorkedCompaniesComputed = computed(() => {
      return store.getters["ExpertModule/getExpertWorkedCompanies"];
    });

    onMounted(async () => {
      loading.value = true;
      try {
        await store.dispatch(
          "ExpertModule/fetchExpertCompaniesWorkedWith",
          false
        );
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    });

    const addCompany = (company) => {
      expertWorkedCompanies.value.push(company);
    };

    const deleteCompany = async (companyId) => {
      loading.value = true;
      try {
        await deleteWorkExperienceOfExpert(companyId);
        await store.dispatch(
          "ExpertModule/fetchExpertCompaniesWorkedWith",
          true
        );
        ElNotification({
          title: "Success",
          message: "Company Deleted.",
          type: "success",
        });
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Company Not deleted",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };

    const submit = async () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      try {
        const form = new FormData();
        form.append("companyName", formData.value.companyName);
        if (formData.value.logo) {
          form.append("logo", formData.value.logo);
        }
        if (companyToBeEdited.value.id) {
          await updateWorkExperienceOfExpert(companyToBeEdited.value.id, form);
        } else {
          await addWorkExperienceOfExpert(form);
        }
        await store.dispatch(
          "ExpertModule/fetchExpertCompaniesWorkedWith",
          true
        );
        ElNotification({
          title: "Success",
          message: companyToBeEdited.value.id
            ? "Company updated"
            : "Company Added",
          type: "success",
        });
        modalRef.value.modalHide();
        companyToBeEdited.value = {};
        clearFormData();
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Error saving data",
          type: "error",
        });
      } finally {
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.removeAttribute("data-kt-indicator");
      }
    };

    const handleClick = () => {
      hiddenInputRef.value.click();
    };

    const handleFileSelected = (event) => {
      formData.value.logo = event.target.files[0];
      displayImage.value = URL.createObjectURL(event.target.files[0]);
    };

    const clearFormData = () => {
      formData.value = { companyName: "" };
      displayImage.value = "";
      addWorkExperienceForm.value.resetForm();
    };

    const editWorkExperienceCompany = (company) => {
      companyToBeEdited.value = company;
      addCompanyButtonRef.value.clickButton();
      setTimeout(() => {
        formData.value.companyName = company.companyName;
        displayImage.value = company.logoURL ? company.logoURL : "";
      }, 100);
    };

    watch(
      () => expertWorkedCompaniesComputed.value,
      (results) => {
        expertWorkedCompanies.value = results;
      },
      { immediate: true, deep: true }
    );

    return {
      expertWorkedCompanies,
      formData,
      validationSchema,
      submitButtonRef,
      modalRef,
      expertId,
      loading,
      hiddenInputRef,
      addCompanyButtonRef,
      addWorkExperienceForm,
      displayImage,
      clearFormData,
      editWorkExperienceCompany,
      handleFileSelected,
      addCompany,
      deleteCompany,
      submit,
      handleClick,
    };
  },
};
</script>

<style scoped lang="scss">
.company-name {
  padding-left: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
}
</style>
