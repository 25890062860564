<template>
  <div class="my-rate-card" v-loading="loading">
    <ContentCard :card-height="26">
      <template v-slot:header>
        <h3 class="primary-text align-self-center" style="margin-top: 0.5rem">
          My Smart Capital
        </h3>
      </template>
      <template v-slot:body>
        <div class="d-flex flex-column justify-content-evenly h-100">
          <div v-for="(rate, index) in expertRatesComputed" :key="index">
            <RateCard
              :is-public="isPublic"
              :rate="rate"
              @buttonClicked="editDiscountRate(rate)"
              :is-type-discount="true"
            >
            </RateCard>
            <hr class="grey-text" />
          </div>
        </div>
      </template>
    </ContentCard>
    <ExpertDiscountEditModal
      v-if="!isPublic"
      :rate="selectedRate"
      ref="expertDiscountEditModal"
    />
  </div>
</template>

<script>
import RatesMenuConfig from "../config/AvailabilitiesMenuConfig";
import ContentCard from "../../common/components/ContentCard";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import RateCard from "./RateCard";
import { MenuComponent } from "@/assets/ts/components";
import { saveExpertRates } from "@/api/expert.api";
import ExpertDiscountEditModal from "@/expert/components/ExpertDiscountEditModal";
export default {
  name: "ExpertRates",
  components: {
    ExpertDiscountEditModal,
    RateCard,
    ContentCard,
  },
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
    expertId: {
      type: Number,
      default: undefined,
    },
  },
  setup() {
    const loading = ref(false);
    const store = useStore();
    const selectedRate = ref({});
    const expertDiscountEditModal = ref(null);
    const expertRatesComputed = computed(() => {
      return store.getters["ExpertModule/getExpertRates"];
    });

    const editDiscountRate = (rate) => {
      selectedRate.value = rate;
      expertDiscountEditModal.value.showModal();
    };

    const rateChanged = async (newRate, rate) => {
      loading.value = true;
      try {
        MenuComponent.hideDropdowns(undefined);
        await saveExpertRates([
          {
            rate: newRate,
            discountPercentage: rate.discountPercentage,
            expertRateId: rate.expertRates.id,
          },
        ]);
        await store.dispatch("ExpertModule/fetchExpertRates", true);
        MenuComponent.reinitialization();
      } catch {
        //
      } finally {
        loading.value = false;
      }
    };

    watch(
      () => expertRatesComputed.value,
      () => {
        setTimeout(() => {
          MenuComponent.reinitialization();
        }, 100);
      }
    );

    return {
      loading,
      RatesMenuConfig,
      expertRatesComputed,
      selectedRate,
      expertDiscountEditModal,
      editDiscountRate,
      rateChanged,
    };
  },
};
</script>

<style scoped></style>
