<template>
  <ContentCard :card-height="26">
    <template v-slot:header>
      <h3 class="primary-text" style="margin-top: 0.5rem">Availabilities</h3>
      <div v-if="!isPublic">
        <AddAttributeButton
          :button-title="addEditButtonText"
          modal-id="availabilityModal"
          :open-modal="true"
        >
          <AddCompanyModal
            ref="modalRef"
            modal-id="availabilityModal"
            title="Add Availability"
          >
            <div class="modal-body py-10 px-lg-17">
              <div
                class="me-n7 pe-7"
                id="kt_modal_create_api_key_scroll"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                data-kt-scroll-offset="300px"
              >
                <div class="d-flex flex-wrap justify-content-between">
                  <div
                    class="mb-5 fv-row sliders"
                    v-for="(item, index) in graphCategories"
                    :key="index"
                  >
                    <label class="fs-5 fw-bold mb-2">{{ item }}</label>
                    <div>
                      <el-slider
                        v-model="payloadYearly[index].value"
                        :step="10"
                        show-stops
                        :format-tooltip="formatToolTip"
                      ></el-slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer flex-center">
              <button
                type="reset"
                id="kt_modal_create_api_key_cancel"
                class="btn btn-white me-3"
              >
                Discard
              </button>
              <button
                ref="submitButtonRef"
                type="submit"
                id="kt_modal_create_api_key_submit"
                class="btn btn-primary"
                @click="submit"
              >
                <span class="indicator-label"> Submit </span>
                <span class="indicator-progress">
                  Please wait...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
          </AddCompanyModal>
        </AddAttributeButton>
      </div>
    </template>
    <template v-slot:body>
      <el-row>
        <el-col :span="24">
          <BarGraph
            v-if="!loading"
            :series="graphSeries"
            :categories="graphCategories"
          />
        </el-col>
      </el-row>
    </template>
  </ContentCard>
</template>

<script>
import ContentCard from "../../common/components/ContentCard";
import BarGraph from "./BarGraph";
import AddAttributeButton from "../../common/components/AddAttributeButton";
import { useRoute } from "vue-router";
import { computed, onMounted, ref } from "vue";
import {
  addExpertYearlyAvailability,
  getExpertAvailabilitiesYearly,
} from "@/api/expert.api";
import AddCompanyModal from "./AddCompanyModal";
import { ElNotification } from "element-plus";
import RatesMenuConfig from "../config/AvailabilitiesMenuConfig";
import { MenuComponent } from "@/assets/ts/components";
export default {
  name: "ExpertAvailabilities",
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AddCompanyModal,
    AddAttributeButton,
    BarGraph,
    ContentCard,
  },
  setup() {
    const route = useRoute();
    const expertId = computed(() => route.params.expertId);
    const payloadYearly = ref([]);
    const payloadHourly = ref({
      perWeek: 0,
      perMonth: 0,
      perQuarter: 0,
    });
    const graphSeries = ref([
      {
        name: "Availabilities",
        data: [],
      },
    ]);
    const graphCategories = ref([]);
    const addEditButtonText = ref("Add Availabilities");
    const loading = ref(true);
    const modalRef = ref(null);
    const submitButtonRef = ref(null);
    const hourlyAvailabilitiesFound = ref(false);

    onMounted(async () => {
      try {
        loading.value = true;
        await fetchExpertAvailabilitiesYearly();
        // await fetchExpertAvailabilitiesHourly();
        loading.value = false;
      } catch ({
        response: {
          data: { statusCode, message },
        },
      }) {
        if (
          statusCode === 404 &&
          message === "Expert hourly availabilities not found"
        ) {
          loading.value = false;
          hourlyAvailabilitiesFound.value = false;
        }
      } finally {
        MenuComponent.reinitialization();
      }
    });

    const fetchExpertAvailabilitiesYearly = async () => {
      const { data } = await getExpertAvailabilitiesYearly(expertId.value);
      populateGraphData(data.data);
    };

    const populateGraphData = (expertAvailabilities) => {
      let sumOfValues = 0;
      payloadYearly.value = [];
      graphCategories.value = [];
      graphSeries.value[0].data = [];
      for (const expertAvailability of expertAvailabilities) {
        graphCategories.value.push(expertAvailability.month.name.slice(0, 3));
        graphSeries.value[0].data.push(expertAvailability.value);
        payloadYearly.value.push({
          value: expertAvailability.value,
          monthId: expertAvailability.month.id,
        });
        sumOfValues += expertAvailability.value;
      }
      if (sumOfValues === 0) {
        addEditButtonText.value = "Add Availabilities";
      } else {
        addEditButtonText.value = "Edit Availabilities";
      }
    };

    // const fetchExpertAvailabilitiesHourly = async () => {
    //   const { data } = await getExpertAvailabilitiesHourly(expertId.value);
    //   payloadHourly.value = {
    //     perWeek: data.data.perWeek,
    //     perMonth: data.data.perMonth,
    //     perQuarter: data.data.perQuarter,
    //   };
    //   hourlyAvailabilitiesFound.value = true;
    //   MenuComponent.reinitialization();
    // };

    const submit = async () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      try {
        await addExpertYearlyAvailability(payloadYearly.value);
        await fetchExpertAvailabilitiesYearly();
        ElNotification({
          title: "Success",
          message: "Availabilities Updated.",
          type: "success",
        });
        modalRef.value.modalHide();
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Availabilities Not Updated",
          type: "error",
        });
      } finally {
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.removeAttribute("data-kt-indicator");
      }
    };

    const onSelectHourlyAvailability = async (hours, type) => {
      const preservedValue = payloadHourly.value[type];
      try {
        payloadHourly.value[type] = hours;
        // await addExpertHourlyAvailability(payloadHourly.value);
        // await fetchExpertAvailabilitiesHourly();
        ElNotification({
          title: "Success",
          message: "Availabilities Updated.",
          type: "success",
        });
        MenuComponent.reinitialization();
      } catch (e) {
        payloadHourly.value[type] = preservedValue;
        ElNotification({
          title: "Error",
          message: "Availabilities Not Updated",
          type: "error",
        });
      }
    };

    const formatToolTip = (item) => {
      return item + "%";
    };

    return {
      loading,
      graphSeries,
      graphCategories,
      payloadYearly,
      submitButtonRef,
      modalRef,
      expertId,
      hourlyAvailabilitiesFound,
      payloadHourly,
      addEditButtonText,
      RatesMenuConfig,
      submit,
      onSelectHourlyAvailability, // Todo clean up extra code
      formatToolTip,
    };
  },
};
</script>

<style scoped>
.sliders {
  width: 30%;
}
</style>
