<template>
  <div class="my-rate-card" v-loading="loading">
    <ContentCard :card-height="26">
      <template v-slot:header>
        <h3 class="primary-text align-self-center" style="margin-top: 0.5rem">
          {{ isPublic ? "Rate Card" : "My Rate Card" }}
        </h3>
      </template>
      <template v-slot:body>
        <div class="d-flex flex-column justify-content-evenly h-100">
          <div v-for="(rate, index) in expertRatesComputed" :key="index">
            <RateCard :rate="rate" :is-public="isPublic">
              <EditProfileDropdown
                unit="€"
                v-if="!isPublic"
                @item-clicked="rateChanged($event, rate)"
                :dropdown-menu-items="
                  RatesMenuConfig[rate.expertRates.type].dropdownMenuItems
                "
              />
            </RateCard>
            <hr class="grey-text" />
          </div>
        </div>
      </template>
    </ContentCard>
  </div>
</template>

<script>
import RatesMenuConfig from "../config/AvailabilitiesMenuConfig";
import ContentCard from "../../common/components/ContentCard";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import RateCard from "./RateCard";
import EditProfileDropdown from "./EditProfileDropdown";
import { MenuComponent } from "@/assets/ts/components";
import { saveExpertRates } from "@/api/expert.api";
export default {
  name: "MyRateCard",
  components: {
    EditProfileDropdown,
    RateCard,
    ContentCard,
  },
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
    expertId: {
      type: Number,
      default: undefined,
    },
  },
  setup() {
    const loading = ref(false);
    const store = useStore();
    const expertRatesComputed = computed(() => {
      return store.getters["ExpertModule/getExpertRates"];
    });

    const rateChanged = async (newRate, rate) => {
      loading.value = true;
      try {
        MenuComponent.hideDropdowns(undefined);
        await saveExpertRates([
          {
            rate: newRate,
            discountPercentage: rate.discountPercentage,
            expertRateId: rate.expertRates.id,
          },
        ]);
        await store.dispatch("ExpertModule/fetchExpertRates", true);
        MenuComponent.reinitialization();
      } catch {
        //
      } finally {
        loading.value = false;
      }
    };

    watch(
      () => expertRatesComputed.value,
      () => {
        setTimeout(() => {
          MenuComponent.reinitialization();
        }, 100);
      }
    );

    return { loading, RatesMenuConfig, expertRatesComputed, rateChanged };
  },
};
</script>

<style scoped></style>
