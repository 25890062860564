<template>
  <el-row :gutter="20">
    <el-col :span="12">
      <ExpertSkills />
    </el-col>
    <el-col :span="12">
      <ExpertStrengths />
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col :span="6">
      <ExpertWorkedCompanies />
    </el-col>
    <el-col :span="12">
      <ExpertInvestmentRange />
    </el-col>
    <el-col :span="6">
      <ExpertLanguages />
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="24">
      <ExpertStrongRelations />
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col :span="6">
      <MyRateCard />
    </el-col>
    <el-col :span="12">
      <ExpertAvailabilities />
    </el-col>
    <el-col :span="6">
      <ExpertRates />
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col :span="8">
      <ExpertIndustries />
    </el-col>
    <el-col :span="8">
      <ExpertBusinessModels />
    </el-col>
    <el-col :span="8">
      <ExpertCustomerGroups />
    </el-col>
  </el-row>
</template>

<script>
import ExpertSkills from "../components/ExpertSkills";
import ExpertStrengths from "../components/ExpertStrengths";
import ExpertWorkedCompanies from "../components/ExpertWorkedCompanies";
import ExpertInvestmentRange from "../components/ExpertInvestmentRange";
import ExpertLanguages from "../components/ExpertLanguages";
import ExpertStrongRelations from "../components/ExpertStrongRelations";
import ExpertAvailabilities from "../components/ExpertAvailabilities";
import ExpertRates from "../components/ExpertRates";
import ExpertIndustries from "../components/ExpertIndustries";
import ExpertBusinessModels from "../components/ExpertBusinessModels";
import ExpertCustomerGroups from "../components/ExpertCustomerGroups";
import MyRateCard from "../components/MyRateCard";
export default {
  name: "ExpertProfileDetails",
  components: {
    MyRateCard,
    ExpertIndustries,
    ExpertRates,
    ExpertAvailabilities,
    ExpertStrongRelations,
    ExpertLanguages,
    ExpertInvestmentRange,
    ExpertWorkedCompanies,
    ExpertStrengths,
    ExpertSkills,
    ExpertBusinessModels,
    ExpertCustomerGroups,
  },
};
</script>

<style scoped></style>
