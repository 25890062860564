<template>
  <ContentCard>
    <template v-slot:header>
      <h3 class="primary-text" style="margin-top: 0.5rem">
        Companies I have strong relations with
      </h3>
      <AddAttributeButton
        @buttonClicked="clearFormData"
        :show-button="!isPublic && expertStrongRelations.length < 6"
        button-title="Add One"
        ref="addStrongRelationModalButtonRef"
        :is-one-line="false"
        modal-id="strongRelationsModal"
        :open-modal="true"
      >
        <AddCompanyModal
          ref="modalRef"
          modal-id="strongRelationsModal"
          :title="strongRelationToBeEdited.id ? 'Edit Company' : 'Add Company'"
        >
          <Form
            id="kt_modal_create_api_key_form"
            ref="addStrongRelationshipForm"
            class="form"
            @submit="submit"
            :validation-schema="validationSchema"
          >
            <div class="modal-body py-10 px-lg-17">
              <div
                class="scroll-y me-n7 pe-7"
                id="kt_modal_create_api_key_scroll"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
                data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
                data-kt-scroll-offset="300px"
              >
                <div class="mb-5 fv-row w-100 text-center">
                  <el-avatar
                    class="cursor-pointer"
                    @click="handleClick"
                    :size="100"
                    :src="displayImage"
                  ></el-avatar>
                  <input
                    name="uploadAvatar"
                    @input="handleFileSelected"
                    type="file"
                    style="display: none"
                    ref="hiddenInputFileRef"
                  /><br />
                  <label class="fs-5 fw-bold mb-2">Logo</label>
                </div>
                <div class="mb-5 fv-row">
                  <label class="required fs-5 fw-bold mb-2">Company Name</label>
                  <Field
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="Company Name"
                    name="companyName"
                    v-model="formData.companyName"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="companyName" />
                    </div>
                  </div>
                </div>
                <div className="mb-5 fv-row">
                  <label className="form-label">Seniority Level</label>
                  <Field
                    name="seniorityLevel"
                    class="form-select form-select-lg form-select-solid"
                    data-control="select2"
                    data-placeholder="Select..."
                    data-allow-clear="true"
                    data-hide-search="true"
                    as="select"
                    v-model="formData.seniorityLevelId"
                  >
                    <option
                      v-for="seniority in seniorityLevels"
                      :key="seniority.id"
                      :value="seniority.id"
                    >
                      {{ seniority.name }}
                    </option>
                  </Field>
                </div>
                <div className="mb-5 fv-row">
                  <label className="form-label">Department</label>
                  <Field
                    name="department"
                    class="form-select form-select-lg form-select-solid"
                    data-control="select2"
                    data-placeholder="Select..."
                    data-allow-clear="true"
                    data-hide-search="true"
                    as="select"
                    v-model="formData.departmentId"
                  >
                    <option
                      v-for="department in departments"
                      :key="department.id"
                      :value="department.id"
                    >
                      {{ department.name }}
                    </option>
                  </Field>
                </div>
              </div>
            </div>
            <div class="modal-footer flex-center">
              <button
                type="button"
                data-bs-dismiss="modal"
                class="btn btn-white me-3"
              >
                Discard
              </button>
              <button
                ref="submitButtonRef"
                type="submit"
                id="kt_modal_create_api_key_submit"
                class="btn btn-primary"
              >
                <span class="indicator-label"> Submit </span>
                <span class="indicator-progress">
                  Please wait...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
          </Form>
        </AddCompanyModal>
      </AddAttributeButton>
    </template>My preferred Industries
    <template v-slot:body>
      <div
        v-if="!expertStrongRelations.length"
        class="d-flex flex-column h-100 align-items-center justify-content-center"
      >
        <el-avatar :size="100" :src="'/media/emptyCard/empty_1.png'">
          <img :src="'/media/emptyCard/empty_1.png'" :alt="'empty_card'"/>
        </el-avatar>
        <h4
          v-if="!expertStrongRelations.length"
          class="primary-text text-center py-5 mt-2"
        >
          Please add some info
        </h4>
      </div>
      <el-row v-loading="loading">
        <el-col
          class="text-center position-relative"
          v-for="(strongRelation, index) in expertStrongRelations"
          :key="index"
          :span="4"
        >
          <div class="symbol symbol-75px w-75px bg-light">
            <img
              :src="
                strongRelation.logoURL
                  ? strongRelation.logoURL
                  : '/media/svg/iconmonstr-building-20.svg'
              "
              alt="image"
              class="p-3"
              style="object-fit: cover"
            />
          </div>
          <h6 class="primary-text mt-2">{{ strongRelation.companyName }}</h6>
          <el-button
            v-if="strongRelation.seniorityLevel"
            class="btn btn-success btn-sm mt-2"
            size="mini"
            round
            >{{ strongRelation.seniorityLevel.name }}</el-button
          >
          <h6 v-if="strongRelation.department" class="primary-text mt-2 fs-7">
            {{ strongRelation.department.name }}
          </h6>
          <div style="top: 0; right: 20px" class="position-absolute">
            <el-icon
              @click="editStrongRelationCompany(strongRelation)"
              v-if="!isPublic"
              class="el-icon--left cursor-pointer primary-text"
              ><Edit
            /></el-icon>
            <el-icon
              @click="deleteStrongRelationCompany(strongRelation.id)"
              v-if="!isPublic"
              class="el-icon--left cursor-pointer primary-text"
              ><CircleCloseFilled
            /></el-icon>
          </div>
          <!--          <el-button-->
          <!--            v-else-->
          <!--            class="btn btn-sm mt-2 btn-primary-rounder"-->
          <!--            size="mini"-->
          <!--            round-->
          <!--            >SENIORITY?</el-button-->
          <!--          >-->
          <!--          <div class="mt-1">-->
          <!--            <span v-if="strongRelation.department" class="primary-text">{{-->
          <!--              strongRelation.department.name-->
          <!--            }}</span>-->
          <!--            <span v-else class="svg-icon svg-icon-1 svg-icon-primary plus-icon">-->
          <!--              <img src="/media/images/small-plus-blue.png" style="width: 20%" />-->
          <!--            </span>-->
          <!--          </div>-->
        </el-col>
      </el-row>
    </template>
  </ContentCard>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import ContentCard from "../../common/components/ContentCard";
import AddAttributeButton from "../../common/components/AddAttributeButton";
import {
  createExpertStrongRelation,
  deleteExpertStrongRelation,
  getStrongRelationsOfExpert,
  updateExpertStrongRelation,
} from "@/api/expert.api";
import AddCompanyModal from "./AddCompanyModal";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { ElNotification } from "element-plus";
import { useRoute } from "vue-router";
import { CircleCloseFilled, Edit } from "@element-plus/icons";

export default {
  name: "ExpertStrongRelations",
  components: {
    Edit,
    CircleCloseFilled,
    AddCompanyModal,
    AddAttributeButton,
    ContentCard,
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const expertId = computed(() => route.params.expertId);
    const hiddenInputFileRef = ref(null);
    const loading = ref(false);
    const addStrongRelationshipForm = ref(null);
    const addStrongRelationModalButtonRef = ref(null);
    const displayImage = ref("");
    const expertStrongRelations = ref([]);
    const strongRelationToBeEdited = ref({});
    const departments = computed(
      () => store.getters["ExpertModule/getDepartments"]
    );
    const seniorityLevels = computed(
      () => store.getters["ExpertModule/getSeniorityLevels"]
    );
    const formData = ref({
      companyName: "",
      departmentId: null,
      seniorityLevelId: null,
    });
    const submitButtonRef = ref(null);
    const modalRef = ref(null);
    const validationSchema = Yup.object().shape({
      companyName: Yup.string().required().label("Company Name"),
    });

    onMounted(async () => {
      try {
        await store.dispatch("ExpertModule/fetchDepartments");
        await store.dispatch("ExpertModule/fetchSeniorityLevels");
        await fetchExpertStrongRelations();
      } catch (e) {
        console.error(e);
      }
    });

    const fetchExpertStrongRelations = async () => {
      const { data } = await getStrongRelationsOfExpert(expertId.value);
      expertStrongRelations.value = data.data;
    };

    const handleClick = () => {
      hiddenInputFileRef.value.click();
    };

    const handleFileSelected = (event) => {
      formData.value.logo = event.target.files[0];
      displayImage.value = URL.createObjectURL(event.target.files[0]);
    };

    const clearFormData = () => {
      formData.value = {
        companyName: "",
        departmentId: null,
        seniorityLevelId: null,
      };
      displayImage.value = "";
      addStrongRelationshipForm.value.resetForm();
    };

    const editStrongRelationCompany = (company) => {
      strongRelationToBeEdited.value = company;
      addStrongRelationModalButtonRef.value.clickButton();
      setTimeout(() => {
        formData.value.companyName = company.companyName;
        formData.value.seniorityLevelId = company.seniorityLevel
          ? company.seniorityLevel.id
          : null;
        formData.value.departmentId = company.department
          ? company.department.id
          : null;
        displayImage.value = company.logoURL ? company.logoURL : "";
      }, 100);
    };

    const submit = async () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      try {
        const form = new FormData();
        form.append("companyName", formData.value.companyName);
        formData.value.departmentId &&
          form.append("departmentId", formData.value.departmentId);
        formData.value.seniorityLevelId &&
          form.append("seniorityLevelId", formData.value.seniorityLevelId);
        formData.value.logo && form.append("logo", formData.value.logo);
        if (strongRelationToBeEdited.value.id) {
          await updateExpertStrongRelation(
            strongRelationToBeEdited.value.id,
            form
          );
        } else {
          await createExpertStrongRelation(form);
        }
        modalRef.value.modalHide();
        await fetchExpertStrongRelations();
        ElNotification({
          title: "Success",
          message: strongRelationToBeEdited.value.id
            ? "Company updated"
            : "Company Added",
          type: "success",
        });
        strongRelationToBeEdited.value = {};
        clearFormData();
      } catch ({
        response: {
          data: { statusCode, message },
        },
      }) {
        ElNotification({
          title: "Error",
          message: message,
          type: "error",
        });
      } finally {
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.removeAttribute("data-kt-indicator");
      }
    };

    const deleteStrongRelationCompany = async (companyId) => {
      loading.value = true;
      try {
        await deleteExpertStrongRelation(companyId);
        await fetchExpertStrongRelations();
      } catch {
        //
      } finally {
        loading.value = false;
      }
    };

    return {
      expertStrongRelations,
      formData,
      validationSchema,
      departments,
      seniorityLevels,
      submitButtonRef,
      modalRef,
      expertId,
      loading,
      displayImage,
      hiddenInputFileRef,
      addStrongRelationModalButtonRef,
      addStrongRelationshipForm,
      strongRelationToBeEdited,
      submit,
      deleteStrongRelationCompany,
      editStrongRelationCompany,
      handleClick,
      handleFileSelected,
      clearFormData,
    };
  },
};
</script>

<style scoped lang="scss">
.companies-container {
  padding-right: 2rem;
  width: 10rem;
}
</style>
