<template>
  <ContentCard :card-height="43">
    <template v-slot:header>
      <h3 class="primary-text" style="margin-top: 0.5rem">
        Strengths of Network
      </h3>
      <AddAttributeButton
        v-if="!isPublic"
        button-title="Add/Edit"
        modal-id="networkStrengthModal"
        :open-modal="true"
      >
        <AddCompanyModal
          ref="modalRef"
          modal-id="networkStrengthModal"
          title="Add Network Strength"
        >
          <div class="modal-body py-10 px-lg-17">
            <div
              class="me-n7 pe-7"
              id="kt_modal_create_api_key_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
              data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
              data-kt-scroll-offset="300px"
            >
              <div
                class="mb-5 fv-row"
                v-for="(item, index) in strengths"
                :key="index"
              >
                <label class="fs-5 fw-bold mb-2">{{ item.name }}</label>
                <div>
                  <el-slider
                    v-model="payload[index].value"
                    :step="20"
                    show-stops
                    :format-tooltip="formatToolTip"
                  ></el-slider>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer flex-center">
            <button
              @click="reset"
              id="kt_modal_create_api_key_cancel"
              class="btn btn-white me-3"
            >
              Discard
            </button>
            <button
              ref="submitButtonRef"
              type="submit"
              id="kt_modal_create_api_key_submit"
              class="btn btn-primary"
              @click="submit"
            >
              <span class="indicator-label"> Submit </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>
        </AddCompanyModal>
      </AddAttributeButton>
    </template>
    <template v-slot:body>
      <div v-loading="loading" class="d-flex flex-column h-100">
        <div v-if="showGraph" class="flex-grow-1">
          <apexchart
            ref="chartRef"
            type="radar"
            height="475"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
        <div
          class="d-flex flex-column h-100 align-items-center justify-content-center"
          v-else-if="!isPublic"
        >
          <el-avatar :size="250" :src="'/media/emptyCard/empty_1.png'">
            <img :src="'/media/emptyCard/empty_1.png'" :alt="'empty_card'"/>
          </el-avatar>
          <h4 class="text-center primary-text mt-10">
            Please add some info
          </h4>
        </div>
        <div
          class="d-flex flex-column h-100 align-items-center justify-content-center"
          v-else
        >
          <h3 class="text-center primary-text">
            The user hasn't added any network strength.
          </h3>
        </div>
      </div>
    </template>
  </ContentCard>
</template>

<script>
import ContentCard from "../../common/components/ContentCard";
import { computed, onMounted, ref } from "vue";
import {
  createExpertNetworkStrength,
  getExpertNetworkStrengths,
  getNetworkStrength,
} from "@/api/expert.api";
import AddAttributeButton from "../../common/components/AddAttributeButton";
import AddCompanyModal from "./AddCompanyModal";
import { ElNotification } from "element-plus";
import { useRoute } from "vue-router";
export default {
  name: "ExpertStrengths",
  components: { AddCompanyModal, AddAttributeButton, ContentCard },
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const route = useRoute();
    const expertId = computed(() => route.params.expertId);
    const strengths = ref([]);
    const loading = ref(true);
    const payload = ref([]);
    const showGraph = ref(false);
    const chartRef = ref(null);
    const modalRef = ref(null);
    const submitButtonRef = ref(null);
    const chartOptions = ref({
      chart: {
        type: "radar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: [],
        labels: {
          show: true,
          style: {
            colors: ["#406E88", "#406E88", "#406E88", "#406E88", "#406E88"],
            fontSize: "15px",
            fontFamily: "Arial",
          },
        },
      },
      tooltip: {
        enabled: true,
        y: {
          title: {
            formatter: (seriesName) => null,
          },
        },
      },
      yaxis: {
        show: true,
        min: 0,
        tickAmount: 5,
        max: 100,
        labels: {
          show: false,
          formatter: function (value) {
            return value + "%";
          },
        },
      },
    });
    const series = ref([
      {
        data: [],
      },
    ]);

    onMounted(async () => {
      try {
        await fetchNetworkStrength();
        // await createExpertNetworkStrength(payload.value);
        await fetchExpertNetworkStrength();
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    });

    const fetchNetworkStrength = async () => {
      const networkStrength = await getNetworkStrength();
      strengths.value = networkStrength.data.data;
      for (const item of networkStrength.data.data) {
        chartOptions.value.xaxis.categories.push(item.name);
        payload.value.push({
          value: 0,
          networkStrengthId: item.id,
        });
      }
    };

    const fetchExpertNetworkStrength = async () => {
      const expertNetworkStrength = await getExpertNetworkStrengths(
        expertId.value
      );
      if (expertNetworkStrength.data.data.length) {
        series.value[0].data = [];
        for (const item of expertNetworkStrength.data.data) {
          series.value[0].data.push(item.value);
          const index = payload.value.findIndex(
            (val) => val.networkStrengthId === item.networkStrength.id
          );
          if (index !== -1) {
            payload.value[index].value = item.value;
          }
        }
        showGraph.value = true;
      } else {
        showGraph.value = false;
      }
    };

    const reset = () => {
      modalRef.value.modalHide();
    };

    const submit = async () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      try {
        await createExpertNetworkStrength(payload.value);
        await fetchExpertNetworkStrength();
        ElNotification({
          title: "Success",
          message: "Network Strength Updated.",
          type: "success",
        });
        modalRef.value.modalHide();
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Network Strength Not Updated",
          type: "error",
        });
      } finally {
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.removeAttribute("data-kt-indicator");
      }
    };
    const formatToolTip = (item) => {
      return item + "%";
    };
    return {
      series,
      chartOptions,
      chartRef,
      loading,
      strengths,
      payload,
      showGraph,
      submitButtonRef,
      modalRef,
      expertId,
      submit,
      reset,
      formatToolTip,
    };
  },
};
</script>

<style scoped></style>
