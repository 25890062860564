<template>
  <div v-loading="loading">
    <ExpertSelectionsCard
      :is-public="isPublic"
      @item-to-add="addIndustry"
      @item-to-delete="deleteIndustry"
      title="My preferred Industries"
      :items="expertIndustries"
      :dropdown-menu-items="industries"
    />
  </div>
</template>

<script>
import ExpertSelectionsCard from "./ExpertSelectionsCard";
import { computed, onMounted, ref } from "vue";
import {
  addExpertIndustry,
  deleteExpertIndustry,
  getIndustriesOfExpert,
} from "../../api/expert.api";
import { getAllIndustries } from "../../api/user.api";
import { ElNotification } from "element-plus";
import { useRoute } from "vue-router";
export default {
  name: "ExpertIndustries",
  components: { ExpertSelectionsCard },
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const route = useRoute();
    const expertId = computed(() => route.params.expertId);
    const expertIndustries = ref([]);
    const industries = ref([]);
    const loading = ref(true);

    onMounted(async () => {
      try {
        industries.value = await getIndustries();
        const industriesOfExperts = await getExpertIndustries();
        for (const expertIndustry of industriesOfExperts) {
          expertIndustries.value.push(expertIndustry.industry);
        }
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    });

    const getIndustries = async () => {
      const { data } = await getAllIndustries();
      return data.data;
    };

    const getExpertIndustries = async () => {
      const { data } = await getIndustriesOfExpert(expertId.value);
      return data.data;
    };

    const addIndustry = async (industry) => {
      const preservedValue = [...expertIndustries.value];
      expertIndustries.value.push(industry);
      try {
        await addExpertIndustry(industry.id);
        ElNotification({
          title: "Success",
          message: "Industry Added",
          type: "success",
        });
      } catch (e) {
        expertIndustries.value = [...preservedValue];
        ElNotification({
          title: "Error",
          message: "Industry Not Added",
          type: "error",
        });
      }
    };

    const deleteIndustry = async (industryId) => {
      const preservedValue = [...expertIndustries.value];
      expertIndustries.value = expertIndustries.value.filter(
        (industry) => industry.id !== industryId
      );
      try {
        await deleteExpertIndustry(industryId);
        ElNotification({
          title: "Success",
          message: "Industry Deleted",
          type: "success",
        });
      } catch (e) {
        expertIndustries.value = [...preservedValue];
        ElNotification({
          title: "Error",
          message: "Industry Not Deleted",
          type: "error",
        });
      }
    };

    return {
      expertIndustries,
      industries,
      loading,
      deleteIndustry,
      addIndustry,
    };
  },
};
</script>

<style scoped></style>
